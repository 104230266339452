var render = function render(){
  var _vm$$store$state$towe, _vm$$store$state$towe2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "short": ""
    }
  }, [_c('v-toolbar-title', [_vm._v(" Region " + _vm._s(_vm.sectionIndex + 1) + "." + _vm._s(_vm.regionIndex + 1) + " ")]), _c('v-btn', {
    attrs: {
      "small": "",
      "icon": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('duplicate');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-file-multiple")])], 1), _c('v-btn', {
    attrs: {
      "small": "",
      "icon": "",
      "disabled": _vm.preventDelete || _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('delete');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1), _c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-row', [_c('v-col', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-radio-group', {
    attrs: {
      "mandatory": "",
      "row": "",
      "dense": "",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.regionType,
      callback: function ($$v) {
        _vm.regionType = $$v;
      },
      expression: "regionType"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Can",
      "value": "CanRegionIntent"
    },
    on: {
      "click": function ($event) {
        _vm.minThickSwitch = false;
      }
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Spiral",
      "value": "SpiralRegionIntent"
    },
    on: {
      "click": function ($event) {
        _vm.minThickSwitch = false;
      }
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Tabular Can",
      "value": "TabularCanRegionIntent"
    }
  })], 1)], 1), _vm.regionType === 'TabularCanRegionIntent' ? _c('v-row', [_c('custom-data-table', {
    attrs: {
      "title": _vm.dataTableTitle,
      "subtitle": "Can Region Intent",
      "headers": _vm.tabularCanRegionTableHeaders,
      "items": _vm.value.cans,
      "value-units": _vm.canRegionValueUnits,
      "data-empty-row": {
        base_diameter: null,
        height: null,
        thickness: null
      },
      "ordered-row-items": ['base_diameter', 'height', 'thickness'],
      "reverseData": true,
      "optional-props": {
        'custom-sort': _vm.customSort,
        'disable-pagination': true,
        'hide-default-footer': true,
        'dense': true,
        class: 'mb-4'
      },
      "showAddCsvBtn": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', {
          type: _vm.value.type,
          cans: $event
        });
      }
    }
  })], 1) : _c('v-row', [_vm.regionType == 'SpiralRegionIntent' ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.slopeConstraints,
      "value": _vm.value.slope_constraint,
      "disabled": _vm.disabled
    },
    on: {
      "change": function ($event) {
        return _vm.updateSlopeConstraint($event);
      }
    }
  })], 1) : _vm._e(), _vm.value.slope_constraint == 'FREE' ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Base Diameter",
      "units": "m",
      "value": _vm.value.base_diameter,
      "required": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('base_diameter', $event, _vm.value);
      }
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Height",
      "units": "m",
      "value": _vm.value.height,
      "required": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('height', $event, _vm.value);
      }
    }
  })], 1)], 1), _vm.regionType == 'SpiralRegionIntent' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Shell Clocking",
      "units": "deg",
      "value": _vm.value.shell_clocking,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('shell_clocking', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Cylinder Sheet Length",
      "units": "m",
      "value": _vm.value.cylinder_sheet_length,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue('cylinder_sheet_length', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "dense": "",
      "input-value": _vm.value.enable_negative_taper,
      "label": "Engable Negative Taper",
      "disabled": _vm.disabled
    },
    on: {
      "change": function ($event) {
        return _vm.updateValue('enable_negative_taper', $event, _vm.value);
      }
    }
  })], 1)], 1) : _vm._e(), _vm.regionType !== 'TabularCanRegionIntent' ? [_c('v-switch', {
    attrs: {
      "label": "Min. Thickness Constraints",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.minThickSwitch,
      callback: function ($$v) {
        _vm.minThickSwitch = $$v;
      },
      expression: "minThickSwitch"
    }
  }), _vm.minThickSwitch ? _c('v-btn', {
    attrs: {
      "icon": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.addMinThickness
    }
  }, [_c('v-icon', [_vm._v(" mdi-plus ")])], 1) : _vm._e(), _vm.minThickSwitch ? _c('v-data-table', {
    attrs: {
      "headers": _vm.minThickHeaders,
      "items": _vm.value.min_thicknesses,
      "hide-default-footer": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: `item.min_thickness`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('numeric-text-field', {
          attrs: {
            "units": "mm",
            "rules": [_vm.formRules.required],
            "disabled": _vm.disabled
          },
          model: {
            value: item.min_thickness,
            callback: function ($$v) {
              _vm.$set(item, "min_thickness", $$v);
            },
            expression: "item.min_thickness"
          }
        })];
      }
    }, {
      key: `item.anchor`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-select', {
          attrs: {
            "items": ['TOP', 'BASE'],
            "disabled": _vm.disabled,
            "rules": [_vm.formRules.required]
          },
          model: {
            value: item.anchor,
            callback: function ($$v) {
              _vm.$set(item, "anchor", $$v);
            },
            expression: "item.anchor"
          }
        })];
      }
    }, {
      key: `item.vertical_offset`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('numeric-text-field', {
          attrs: {
            "units": "m",
            "rules": [_vm.formRules.required],
            "disabled": _vm.disabled
          },
          model: {
            value: item.vertical_offset,
            callback: function ($$v) {
              _vm.$set(item, "vertical_offset", $$v);
            },
            expression: "item.vertical_offset"
          }
        })];
      }
    }, {
      key: `item.action`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "disabled": _vm.disabled
          },
          on: {
            "click": function ($event) {
              _vm.deleteMinThickness(_vm.value.min_thicknesses.indexOf(item));
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }], null, true)
  }) : _vm._e()] : _vm._e()], 2)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [(_vm$$store$state$towe = _vm.$store.state.towerIntent) !== null && _vm$$store$state$towe !== void 0 && (_vm$$store$state$towe2 = _vm$$store$state$towe.model) !== null && _vm$$store$state$towe2 !== void 0 && _vm$$store$state$towe2.sections ? _c('unit-results', {
    attrs: {
      "results": _vm.regionResults
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }